/*Bootstrap core imports - adjust for your case*/
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/bootstrap";

// Bootstrap Icons code
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

// $primary: #1ab67d;
$custom-colors: (
  "subtleprimary": #cfe2ff,
  "light-blue": #edf5ff,
  "facebook": #385499,
  "google": #ffffff,
  "light-gray": #f2f3f5,
  "primary": #1ab67d,
  "white": #ffffff,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

.chat-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Styling for mobile devices */
@media (max-width: 767px) {
  .chat-container {
    height: 60vh;
  }
}

.chat-box {
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

.message {
  padding: 5px 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  max-width: 70%;
}

.message.sent {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.message.received {
  background-color: #e9ecef;
  align-self: flex-start;
}

.jumbotron-cover {
  // background-image: url("https://res.cloudinary.com/dszd4owdm/image/upload/v1709073054/SoloStream/header_mmcclp.jpg");
  background-image: url("https://res.cloudinary.com/dx4nlfeak/image/upload/v1713029387/solostreamerProfilePictures/user/mdqw2ljeux8lrvq3rvpg.jpg");
  background-size: cover; /* This ensures the background image covers the entire container */
  background-position: center; /* This centers the background image */
  color: #fff; /* You may need to adjust text color for better visibility */
}
.main-page-cover {
  background-image: url("https://res.cloudinary.com/dszd4owdm/image/upload/v1712848853/h_zee86s.jpg");
  //   background-image: url("https://res.cloudinary.com/dszd4owdm/image/upload/v1707879231/Untitled_design_2_cjfkxr.jpg");
  background-size: cover;
  background-position: center;
  color: #fff; /* You may need to adjust text color for better visibility */
}

// import bootstrap whole sass lib (incorporates all above redefined vars & values)
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss"; // @import "~react-image-gallery/styles/scss/image-gallery.scss";

// import bootstrap whole sass lib (incorporates all above redefined vars & values)
// @import "../../node_modules/bootstrap/scss/bootstrap";
// @import "../../node_modules/bootstrap/scss/bootstrap";
